import React from "react"
import { Box, Text } from "theme-ui"
import SEO from "../../../components/SEO"
import Layout from "../../../components/Layout"
import PageHeader from "../../../components/PageHeader"
import ContentSection from "../../../components/ContentSection"

const MainsTestSeries = () => {
  return (
    <Box>
      <SEO title="mains Test Series " />
      <Layout>
        <PageHeader>
          <Text
            sx={{ fontSize: [3, 5], fontWeight: "bold", textAlign: "center" }}
          >
            Mains Test Series
          </Text>
        </PageHeader>
        <ContentSection
          header="Mains Test Series"
          body={
            <Box
              sx={{
                maxWidth: "800px",
                marginTop: ["-50px", "auto"],
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "auto",
                textAlign: "justify",
              }}
            >
              <Text sx={{ fontSize: [0, 3] }} my={4}>
                1. <span style={{ fontWeight: "800" }}>EXAM SIMULATION: </span>
                THEY REPLICATE THE ACTUAL EXAM ENVIRONMENT, HELPING YOU GET
                ACCUSTOMED TO TIME CONSTRAINTS AND PRESSURE.
              </Text>

              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                2.{" "}
                <span style={{ fontWeight: "800" }}>
                  ANSWER WRITING PRACTICE:{" "}
                </span>{" "}
                REGULAR PRACTICE IMPROVE YOUR ANSWER SKILLS, A CRUCIAL ASPECT OF
                THE UPSC MAIN EXAM
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                3.{" "}
                <span style={{ fontWeight: "800" }}>
                  FEEDBACK AND EVALUATION:{" "}
                </span>
                TEST SERIES PROVIDE DETAILED FEEDBACK ON YOUR PERFORMANCE,
                HIGHLIGHTING STRENGTHS AND AREAS FOR IMPROVEMENT.
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                4. <span style={{ fontWeight: "800" }}>CONTENT COVERAGE:</span>{" "}
                THEY COVER A WIDE RANGE OF TOPICS, ENSURING COMPREHENSIVE
                PREPARATION ACOSS THE UPSC SYLLABUS.
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                5. <span style={{ fontWeight: "800" }}>SELF ASSESSMENT: </span>{" "}
                ASSESSING YOUR PROGRESS THROUGH PERIODIC TESTS HELPS YOU IDENTIY
                WEEK AREAS AND FOCUS ON TARGETED REVISION.
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                6. <span style={{ fontWeight: "800" }}> TIME MANAGEMENT:</span>{" "}
                PRACTICING WITHIN A FIXED TIME FRAME ENHANCES YOUR ABILITY TO
                MANAGE TIME EFFECTIVELY DURING THE ACTUAL EXAM.
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                7.{" "}
                <span style={{ fontWeight: "800" }}>CONFIDENCE BUILDING: </span>{" "}
                REGULAR PARTICIPATION IN TEST SERIES BOOSTS CONFIDENE, REDUCING
                EXAM- RELATED ANXIETY.
              </Text>
            </Box>
          }
        />
      </Layout>
    </Box>
  )
}
export default MainsTestSeries
